<template>
  <div class="wrapper">
    <div :class="`${layout}-streams-container stream-count-${streamList.length}`">
      <div
        v-for="stream in streamList"
        :id="`stream-${stream.id}`"
        :key="stream.id"
        :class="`${layout}-stream ${highlightedStream === stream.id && 'highlighted'}`"
        @click="layout === 'highlight' && (highlightedStream = stream.id)"
      />
    </div>
    <div v-if="isLoaded && role === 'host'" class="btn-container" color="transparent">
      <div><switch-video-button :is-video-enabled="isVideoEnabled" @click="switchVideo()" /></div>
      <div><exit-button @click="exit()" /></div>
      <div><switch-audio-button :is-audio-enabled="isAudioEnabled" @click="switchAudio()" /></div>
      <div>
        <switch-share-screen-button
          :is-sharing-screen="shareScreen.isSharing"
          :disabled="isShareScreenDisabled"
          @click="switchShareScreen()"
        />
      </div>
      <div>
        <more-options-button
          :devices="devices"
          :camera-id="cameraId"
          :microphone-id="microphoneId"
          @switchLayout="switchLayout"
          @switchDevice="switchDevice"
          @finish="$confirm($t('common.confirm')).then( (res) => res && $emit('finish'))"
          @updateDevices="updateDevices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk'
import { AGORA_APP_ID } from '@/config/agora'

export default {
  components: {
    ExitButton: () => import('@/modules/streaming/buttons/ExitButton'),
    SwitchAudioButton: () => import('@/modules/streaming/buttons/SwitchAudioButton'),
    SwitchVideoButton: () => import('@/modules/streaming/buttons/SwitchVideoButton'),
    MoreOptionsButton: () => import('@/modules/streaming/buttons/MoreOptionsButton'),
    SwitchShareScreenButton: () => import('@/modules/streaming/buttons/SwitchShareScreenButton'),
  },
  props: {
    channel: { type: String, required: true },
    role: { type: String, required: true },
  },
  data() {
    return {
      appId: AGORA_APP_ID,
      client: null,
      localStream: null,
      shareScreen: {
        client: null,
        stream: null,
        isSharing: false,
      },
      uid: null,
      streamList: [],
      layout: 'grid', // the other option is hightlight
      devices: [],
      highlightedStream: null,
      isLoaded: false,
      cameraId: null,
      microphoneId: null,
    }
  },
  computed: {
    isAudioEnabled: ({ localStream }) => !localStream?.userMuteAudio,
    isVideoEnabled: ({ localStream }) => !localStream?.userMuteVideo,
    isAnyoneSharingScreen: ({ streamList }) => streamList.some(stream => stream.id === 111111111),
    isShareScreenDisabled() {
      return (this.isAnyoneSharingScreen && !this.shareScreen.isSharing) || !this.$store.state.streaming.dbData?.recordingTemp
    },
  },
  async created() {
    this.$store.commit('loader/show')
    // Create and init the client
    this.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' })
    await new Promise(resolve => this.client.init(this.appId, () => resolve()))
    // Set role
    this.client.setClientRole(this.role)
    // Join the channel
    this.uid = await new Promise(resolve => this.client.join(null, this.channel, null, uid => resolve(uid)))
    // Handle remote users joining and leaving
    this.client.on('stream-added', async ev => {
      const uid = ev.stream.getId()
      if (uid === 111111111 && this.shareScreen.isSharing) return // Do not subscribe to our own screen
      this.client.subscribe(ev.stream)
      if (uid === 111111111) { this.highlightedStream = uid; this.layout = 'highlight' }
      this.addStream({ uid, stream: ev.stream })
    })
    this.client.on('peer-leave', ev => {
      if (ev.uid === this.highlightedStream) {
        this.layout = 'grid'
        this.highlightedStream = this.uid
      }
      if (ev.stream) ev.stream.stop(`stream-${ev.uid}`)
      this.streamList = this.streamList.filter(stream => stream.id !== ev.uid)
    })
    // Create and publish local audio and video tracks
    if (this.role === 'audience') { this.$store.commit('loader/hide'); return }
    await this.updateDevices()
    this.cameraId = this.devices.find(device => device.kind === 'videoinput').deviceId
    this.microphoneId = this.devices.find(device => device.kind === 'audioinput').deviceId
    this.localStream = AgoraRTC.createStream({
      streamId: this.uid, audio: true, video: true, screen: false, cameraId: this.cameraId, microphoneId: this.microphoneId,
    })
    try {
      await new Promise((resolve, reject) => this.localStream.init(() => resolve(), () => reject()))
    } catch (err) {
      this.$store.dispatch('alert/openAlertBox', ['alertError', this.$t('streaming.permissionError')])
    }
    this.client.publish(this.localStream)
    this.addStream({ uid: this.uid, stream: this.localStream, own: true })
    this.highlightedStream = this.uid
    this.doSafariFix()
    this.$store.commit('loader/hide')
    this.isLoaded = true
  },
  beforeDestroy() {
    if (this.localStream) this.localStream.close()
    this.client.unpublish(this.localStream)
    this.client.leave()
    if (this.shareScreen.stream) {
      this.shareScreen.stream.close()
      this.shareScreen.client.unpublish(this.shareScreen.stream)
      this.shareScreen.client.leave()
    }
  },
  methods: {
    addStream({ uid, stream, own = false }) {
      if (own) this.streamList.unshift({ id: uid, streamObj: stream }) // User own stream always go first
      else this.streamList.push({ id: uid, streamObj: stream })
      this.$nextTick(() => stream.play(`stream-${uid}`))
    },
    switchAudio() {
      if (this.isAudioEnabled) this.localStream.muteAudio()
      else this.localStream.unmuteAudio()
    },
    switchVideo() {
      if (this.isVideoEnabled) this.localStream.muteVideo()
      else this.localStream.unmuteVideo()
    },
    async switchDevice({ type, device }) {
      const changeToMagewell = type === 'video' && device.label.includes('HDMI')
      if (changeToMagewell && this.isAnyoneSharingScreen) return
      this.$store.commit('loader/show')
      this.localStream.close()
      this.client.unpublish(this.localStream)
      this.localStream.stop(`stream-${this.uid}`)
      this.streamList = []
      this.client.leave()
      await this.client.setClientRole(this.role)
      this.cameraId = type === 'video' ? device.deviceId : this.cameraId
      this.microphoneId = type === 'audio' ? device.deviceId : this.microphoneId
      const isMagewell = this.devices.find(d => d.deviceId === this.cameraId).label.includes('HDMI')
      this.uid = isMagewell ? 111111111 : Math.floor(Math.random() * 1000000000)
      this.localStream = AgoraRTC.createStream({
        streamId: this.uid,
        audio: true,
        video: true,
        screen: false,
        cameraId: this.cameraId,
        microphoneId: this.microphoneId,
      })
      await new Promise((resolve, reject) => this.localStream.init(() => resolve(), () => reject()))
      await new Promise(resolve => this.client.join(null, this.channel, this.uid, () => resolve()))
      this.client.publish(this.localStream)
      this.addStream({ uid: this.uid, stream: this.localStream, own: true })
      this.$store.commit('loader/hide')
    },
    async updateDevices() {
      this.devices = await new Promise(resolve => AgoraRTC.getDevices(devices => resolve(devices)))
    },
    switchShareScreen() {
      if (this.shareScreen.isSharing) this.stopShareScreen()
      else this.startShareScreen()
    },
    async startShareScreen() {
      this.shareScreen.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' })
      await new Promise(resolve => this.shareScreen.client.init(this.appId, () => resolve()))
      this.shareScreen.client.setClientRole(this.role)
      this.shareScreen.stream = AgoraRTC.createStream({ streamId: 111111111, audio: false, video: false, screen: true, screenAudio: true })
      this.shareScreen.stream.on('stopScreenSharing', () => { this.stopShareScreen() })
      try {
        await new Promise((resolve, reject) => this.shareScreen.stream.init(() => resolve(), () => reject()))
      } catch (err) { this.shareScreen.client.leave(); return }
      await new Promise(resolve => this.shareScreen.client.join(null, this.channel, 111111111, () => resolve()))
      this.shareScreen.client.publish(this.shareScreen.stream)
      this.$store.dispatch('streaming/setSharedScreenRecordingLayout', this.$store.state.streaming.dbData.id)
      this.shareScreen.isSharing = true
    },
    stopShareScreen() {
      this.$store.dispatch('streaming/unsetSharedScreenRecordingLayout', this.$store.state.streaming.dbData.id)
      if (this.shareScreen.stream) {
        this.shareScreen.stream.close()
        this.shareScreen.client.unpublish(this.shareScreen.stream)
        this.shareScreen.client.leave()
      }
      this.shareScreen.isSharing = false
    },
    switchLayout() {
      this.layout = (this.layout === 'grid') ? 'highlight' : 'grid'
    },
    exit() {
      this.$router.push({ name: 'activity' }).catch(() => console.log('Redirection to streaming activity failed because the user is not logged in'))
    },
    doSafariFix() {
      // This fix ensures that Safari users can hear the rest of the hosts when they enter a room that already has hosts
      // Safari blocks audio until the user accepts the security pop-up on this.localStream.init(). So we play all the streams again after
      for (const { id, streamObj } of this.streamList) {
        if (id === this.uid) continue
        streamObj.stop()
        streamObj.play(`stream-${id}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  flex: 1;
  height: 100%;
}

.btn-container {
  position: absolute;
  bottom: 0;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr repeat(3, auto) 1fr auto;
  width: 100%;
  padding: 20px 30px;

  ::v-deep div:nth-child(1) {
    grid-column-start: 2;
  }

  ::v-deep div:nth-child(4) {
    margin-left: auto;
  }
}

::v-deep video {
  transform: rotateY(180deg);
}

// Don't crop share screen stream
::v-deep #stream-111111111 video {
  object-fit: contain !important;
  transform: none;
}

// Grid layout
.grid-streams-container {
  display: grid;
  grid-gap: 2px;
  height: 100%;
  background-color: #cecece;

  &.stream-count-1,
  &.stream-count-2 {
    grid-auto-flow: column;
  }

  &.stream-count-3,
  &.stream-count-4 {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }

  &.stream-count-5,
  &.stream-count-6 {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }

  &.stream-count-7,
  &.stream-count-8,
  &.stream-count-9 {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }

  &.stream-count-10,
  &.stream-count-11,
  &.stream-count-12 {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }

  &.stream-count-13,
  &.stream-count-14,
  &.stream-count-15,
  &.stream-count-16 {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
}

// Highlight layout
.highlight-streams-container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(5, 1fr);
  height: 100%;
  padding: 20px;
  direction: rtl;
}

.highlight-stream {
  z-index: 1;
  overflow: hidden;
  border: 2px solid #cecece;
  cursor: pointer;

  &.highlighted {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    border: none;
    cursor: auto;
  }
}
</style>
